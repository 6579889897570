<template>
    <div class="sidebar-content">
        <div class="links">
            <ul class="nav nav-pills flex-column mb-auto">
                <li>
                    <router-link class="btn nav-link" :to="{ name: 'dashboard' }">
                        <div class="link-name"><i class="bi bi-music-note-list"></i>My {{ $t("linkSongs") }}</div>
                        <div class="songs-count">{{ songsCount }}</div>
                    </router-link>
                </li>
            </ul>

            <template v-if="notebooks.length > 0">
                <hr />

                <div class="nav-section-header">
                    <i class="bi bi-journal-bookmark-fill"></i
                    ><span class="nav-section-header-text">{{ $t("myNotebooks") }}</span>
                </div>
                <ul class="nav nav-pills flex-column mb-auto">
                    <li v-for="notebook in notebooks">
                        <router-link class="btn nav-link nav-sub-link" :to="{ name: 'notebook', params: { notebook } }">
                            <div class="link-name">{{ notebook }}</div>
                            <div class="songs-count">{{ notebookCount(notebook) }}</div>
                        </router-link>
                    </li>
                </ul>
            </template>

            <hr />
            <ul class="nav nav-pills flex-column mb-auto">
                <li>
                    <router-link class="btn nav-link" :to="{ name: 'archive' }">
                        <div class="link-name"><i class="bi bi-archive"></i>{{ $t("linkArchive") }}</div>
                        <div class="songs-count">{{ archivedSongsCount }}</div>
                    </router-link>
                </li>
                <li>
                    <router-link class="btn nav-link" :to="{ name: 'trash' }">
                        <div class="link-name"><i class="bi bi-trash"></i>{{ $t("linkTrash") }}</div>
                        <div class="songs-count">{{ trashedSongsCount }}</div></router-link
                    >
                </li>
            </ul>

            <hr />

            <ul class="nav nav-pills flex-column mb-auto">
                <li>
                    <router-link class="btn nav-link" :to="{ name: 'settings' }">
                        <div class="link-name"><i class="bi bi-gear"></i>{{ $t("linkSettings") }}</div>
                    </router-link>
                </li>
                <li>
                    <a class="btn nav-link" href="https://my-guitar-tabs.com/docs">
                        <div class="link-name"><i class="bi bi-question-circle"></i>{{ $t("linkManual") }}</div>
                    </a>
                </li>
                <li>
                    <a class="btn nav-link" href="https://my-guitar-tabs.com/contact">
                        <div class="link-name"><i class="bi bi-chat-square-text"></i>{{ $t("linkContactUs") }}</div>
                    </a>
                </li>
                <li>
                    <a class="btn nav-link" href="https://my-guitar-tabs.com/discord">
                        <div class="link-name"><i class="bi bi-discord"></i>Discord</div>
                    </a>
                </li>
                <li v-if="showAndroidRatingLink">
                    <a class="btn nav-link" href="https://play.google.com/store/apps/details?id=com.my_guitar_tabs">
                        <div class="link-name"><i class="bi bi-google-play"></i>{{ $t("rateApp") }}</div>
                    </a>
                </li>
                <li v-if="showiOSRatingLink">
                    <a
                        class="btn nav-link"
                        href="https://itunes.apple.com/us/app/appName/id6560117297?mt=8&action=write-review">
                        <div class="link-name"><i class="bi bi-apple"></i>{{ $t("rateApp") }}</div>
                    </a>
                </li>
                <li v-if="shouldShowInstall">
                    <button class="btn nav-link" @click="install">
                        <div class="link-name"><i class="bi bi-download"></i>{{ $t("linkInstall") }}</div>
                    </button>
                </li>
            </ul>
        </div>
        <div class="app-version">
            <div>
                <div>{{ $t("appName") }} {{ appVersion }}</div>
                <div class="app-version-status">
                    {{ needsReload ? $t("settingsNewVersionAvaiable") : $t("settingsUsingLatestVersion") }}
                </div>
            </div>
            <div>
                <button @click="reloadApp" v-if="needsReload" class="btn btn-warning">
                    <i class="bi bi-arrow-repeat"></i>{{ $t("settingsUpdateApp") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { SongStatus } from "@common/song-status";
import { defineComponent } from "vue";
import { api } from "../../apis/api";

export default defineComponent({
    computed: {
        showAndroidRatingLink(): boolean {
            return this.$store.config.appVersion === "android";
        },
        showiOSRatingLink(): boolean {
            return api.auth.isIOSApp;
        },
        songsCount(): number {
            return (
                this.$store.song.find({ status: SongStatus.Default }).length +
                this.$store.song.find({ status: SongStatus.Pinned }).length
            );
        },
        archivedSongsCount(): number {
            return this.$store.song.find({ status: SongStatus.Archived }).length;
        },
        trashedSongsCount(): number {
            return this.$store.song.find({ status: SongStatus.Trashed }).length;
        },
        shouldShowInstall(): boolean {
            return this.$store.install.shouldShow();
        },
        appVersion(): string {
            return APP_VERSION ? `v${APP_VERSION}` : "";
        },
        notebooks(): string[] {
            return this.$store.config.userNotebooks;
        },
        needsReload(): boolean {
            return this.$store.user.needReload;
        },
    },
    methods: {
        install(): void {
            this.$store.install.prompt();
        },
        notebookCount(notebook: string): number {
            return (
                this.$store.song.find({ status: SongStatus.Default, notebook }).length +
                this.$store.song.find({ status: SongStatus.Pinned, notebook }).length
            );
        },
        async reloadApp(): Promise<void> {
            await this.$store.user.reload();
        },
    },
});
</script>

<style lang="scss" scoped>
.sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .nav-section-header {
        padding-left: 12px;
        padding-bottom: 12px;
    }

    .nav-pills {
        margin-bottom: 0 !important;
        padding-right: 12px;

        li {
            .nav-link {
                align-items: center;
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                display: flex;
                height: 53px;
                justify-content: space-between;
                width: 100%;

                &.nav-sub-link {
                    font-weight: 300;
                }

                .songs-count {
                    padding: 5px;
                }
            }
        }
    }

    i {
        margin-right: 5px;
    }

    .app-version {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 8px 12px;

        .app-version-status {
            font-size: 14px;
            font-weight: 300;
        }
    }
}
</style>
