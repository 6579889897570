<template>
    <nav class="header navbar navbar-fixed-top">
        <div class="container container-fluid">
            <i
                class="btn bi bi-arrow-left back"
                @click="onNavigateBack"
                :title="$t('back')"
                @keydown.enter="onNavigateBack"
                tabindex="0"></i>
            <div class="toolbar d-flex align-items-center flex-grow-1 gap-1">
                <song-actions :song="song" :songActionsOptions="songActionsOptions" displayMode="auto"></song-actions>
                <button
                    class="btn btn-primary"
                    :class="{ disabled: isSaving }"
                    :title="doneButtonText"
                    @click="onEditingDone">
                    <i v-if="isSaving" class="spinner-border-overlay spinner-border-sm" role="status"></i>
                    <i class="bi bi-check-lg" :class="{ hide: isSaving }"></i>
                </button>
                <user-dropdown-menu></user-dropdown-menu>

                <zoom-controls></zoom-controls>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import SongActions from "@components/song-actions/song-actions.vue";
import ZoomControls from "@components/zoom-controls/zoom-controls.vue";
import UserDropdownMenu from "@components/user-dropdown-menu/user-dropdown-menu.vue";
import { defineComponent, PropType } from "vue";
import type { Song } from "../../../models/song/song";
import { SongActionOptions, SongActionsType } from "../../../interfaces/song-actions";

export default defineComponent({
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    data() {
        return {
            isSaving: false,
            songActionsOptions: {
                edit: { hide: true },
                copy: { hide: true },
                archive: { hide: true },
                trash: { hide: true },
                share: { hide: true },
                delete: { hide: true },
                moveToNotebook: { hide: true },
            } as Record<SongActionsType, SongActionOptions>,
        };
    },
    components: {
        "song-actions": SongActions,
        "zoom-controls": ZoomControls,
        "user-dropdown-menu": UserDropdownMenu,
    },
    computed: {
        isNewSong(): boolean {
            return this.$store.editor.isNewSong;
        },
        loggedIn(): boolean {
            return this.$store.user.loggedIn;
        },
        doneButtonText(): string {
            return this.$t("doneEditing");
        },
        allowSave(): boolean {
            return this.isNewSong || this.$store.editor.hasSongChanged;
        },
    },
    methods: {
        onColorChange(color?: string): void {
            this.song.tags.color = color;
            this.$store.editor.markAsChanged();
        },
        onNavigateBack(): void {
            if (this.song.isInTrash()) {
                this.$router.push({ name: "trash" });
            } else if (this.song.isArchived()) {
                this.$router.push({ name: "archive" });
            } else {
                const notebook = (this.song.metadata.labels ?? [])[0];
                if (notebook) {
                    this.$router.push({
                        name: "notebook",
                        params: { notebook },
                    });
                } else {
                    this.$router.push({
                        name: "dashboard",
                    });
                }
            }
        },
        async onEditingDone(): Promise<void> {
            this.isSaving = true;

            try {
                if (this.allowSave) {
                    await this.$store.editor.saveSong();
                }
                this.$router.push({ name: "song", query: { song: this.$store.editor.selectedSongId } });
            } finally {
                this.isSaving = false;
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.header {
    background-color: var(--background-color-primary);
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;

    .container {
        column-gap: 25px;
        flex-wrap: nowrap;
        position: relative;

        .encouraging-sentence {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            user-select: none;
            color: var(--text-color);
        }

        .save-icon {
            &.hide {
                opacity: 0;
            }
        }
    }
}
</style>
