<template>
    <div class="editor-song container">
        <form class="d-flex flex-column mt-lg-0" role="text">
            <input
                class="form-control title"
                type="text"
                maxlength="100"
                tabindex="0"
                v-model="song.title"
                :placeholder="$t('songTitle')"
                :readonly="!enableInputs"
                @change="onTagChange"
                @focus="onTagFocus" />
            <input
                class="form-control info"
                type="text"
                maxlength="100"
                tabindex="0"
                v-model="song.tags.comment"
                :placeholder="$t('songExtraInfo')"
                :readonly="!enableInputs"
                @change="onTagChange"
                @focus="onTagFocus" />
        </form>
    </div>
</template>

<script lang="ts">
import type { Song } from "models/song/song";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        enableInputs: {
            type: Boolean as PropType<Boolean>,
            required: true,
        },
    },
    emits: {
        tagChange: () => true,
        tagFocus: () => true,
    },
    methods: {
        onTagChange(): void {
            this.$emit("tagChange");
        },
        onTagFocus(): void {
            this.$emit("tagFocus");
        },
    },
});
</script>

<style lang="scss" scoped>
.editor-song {
    height: 110px;
    width: 100%;

    input {
        background: none;
        border: none;
        border-radius: 0;
        padding: 0;
        width: auto;

        &::placeholder {
            opacity: 0.65;
        }

        &:read-only {
            cursor: text;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .title,
    .info {
        text-align: center;
        margin: 5px;
    }

    .title {
        font-size: 38px;
    }

    .song-artist {
        font-size: 1.25rem;
    }
}
</style>
