<template>
    <div class="songs-summary">
        <template v-if="hasResults">
            <div v-if="hasSearch" class="notification search-results">
                <i class="bi bi-search"></i>
                <span>{{ $t("searchMatches", { count: resultsCount }) }}</span>
            </div>
        </template>
        <template v-else>
            <div v-if="hasSearch" class="notification no-search-results">
                <i class="bi bi-search"></i>
                <span>{{ $t("noSongsMatchSearch") }}</span>
            </div>
            <div v-else class="notification no-songs">
                <i class="bi bi-music-note"></i>
                <span v-if="isSyncing">{{ $t("syncInitialSongsLoad") }}</span>
                <span v-else
                    >{{ $t("noSongs") }}
                    <a
                        v-if="showCreateSong"
                        class="d-block"
                        href="#"
                        @click="createNewSong"
                        @keydown.enter="createNewSong"
                        >{{ $t("createNewSong") }}
                    </a></span
                >
            </div>
        </template>
        <div v-if="selectedNotebook !== undefined" class="selected-notebook">
            <i class="bi bi-journal-bookmark-fill"></i> {{ selectedNotebook }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SyncStatus } from "../../../../common/constants";

export default defineComponent({
    props: {
        searchValue: {
            type: String,
            default: "",
        },
        resultsCount: {
            type: Number,
            default: 0,
        },
        showCreateSong: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasResults(): boolean {
            return this.resultsCount > 0;
        },
        hasSearch(): boolean {
            return this.searchValue !== "";
        },
        syncStatus(): SyncStatus {
            return this.$store.song.syncStatus;
        },
        isSyncing(): boolean {
            return this.syncStatus === SyncStatus.IN_PROGRESS;
        },
        selectedNotebook(): string | undefined {
            return this.$store.config.selectedNotebook;
        },
        canAddSong(): boolean {
            return this.$store.song.canAddSong;
        },
    },
    methods: {
        async createNewSong(): Promise<void> {
            if (!this.canAddSong) {
                this.$bus.emit("error", this.$t("songsLimitError"));
                return;
            }

            this.$router.push({
                name: "create",
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.songs-summary {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 12px;
    height: 34px;

    .notification {
        border-left: solid 1px var(--text-color-light);
        color: var(--text-color-light);
        padding: 5px 10px;
        display: flex;
        gap: 8px;
    }

    .selected-notebook {
        color: var(--gray-700);
        margin-left: auto;
        padding: 5px 0;
        white-space: nowrap;

        @media (min-width: 1400px) {
            display: none;
        }
    }
}
</style>
