<template>
    <nav class="header navbar navbar-fixed-top">
        <div class="container container-fluid">
            <i
                class="btn bi bi-arrow-left back"
                @click="onNavigateBack"
                :title="$t('back')"
                @keydown.enter="onNavigateBack"
                tabindex="0"></i>

            <div class="toolbar toolbar-right d-flex">
                <i class="bi bi-gear"></i>
                <div class="page-name">{{ $t("linkSettings") }}</div>
            </div>

            <div class="toolbar d-flex align-items-center gap-1">
                <user-dropdown-menu></user-dropdown-menu>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserDropdownMenu from "@components/user-dropdown-menu/user-dropdown-menu.vue";

export default defineComponent({
    components: {
        "user-dropdown-menu": UserDropdownMenu,
    },
    methods: {
        onNavigateBack(): void {
            this.$router.push({
                name: "dashboard",
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.header {
    background-color: var(--background-color-secondary);
    border-bottom: solid 1px var(--gray-300);
}

.toolbar-right {
    i {
        margin-right: 5px;
    }
}
</style>
