import type { TablaturePosition } from "../../interfaces/tablature-position";
import type { TablatureRange } from "../../interfaces/tablature-range";
import { isTablatureRange } from "../../interfaces/tablature-range";
import { getEditorStore } from "../../stores/editor.store";
import { EditorCursor } from "./editor-cursor";
import { EditorNavigation } from "./editor-navigation";

export class EditorSelection {
    public readonly navigate: EditorNavigation;
    public readonly cursor: EditorCursor;
    private _range?: TablatureRange;

    public stringCount: number;
    public inProgress: boolean = false;

    /** Selected position, acts as anchor if it is a range */
    private _selectedPosition?: TablaturePosition;

    private _previousPosition?: TablaturePosition;
    private lastSelectedPosition?: TablaturePosition;

    constructor(stringCount: number = 6) {
        this.stringCount = stringCount;
        this.navigate = new EditorNavigation(this);
        this.cursor = new EditorCursor(this);
    }

    public get previousPosition(): TablaturePosition | undefined {
        return this._previousPosition ? { ...this._previousPosition } : undefined;
    }

    private set previousPosition(pos: TablaturePosition | undefined) {
        this._previousPosition = pos;
    }

    private get selectedPosition(): TablaturePosition | undefined {
        return this._selectedPosition;
    }

    private set selectedPosition(pos: TablaturePosition | undefined) {
        this._selectedPosition = pos;
        if (pos !== undefined) this.lastSelectedPosition = pos;
    }

    public setSongStrings(stringCount: number) {
        this.stringCount = stringCount;
    }

    public get range(): TablatureRange | undefined {
        return this._range;
    }

    public getSelectedEventIndexes(): number[] {
        if (this.range) {
            const fromIndex = this.range.from.eventIndex;
            const toIndex = this.range.to.eventIndex;
            const length = toIndex - fromIndex + 1;
            return Array.from({ length }, (_, i) => fromIndex + i);
        }
        if (this.selectedPosition) {
            return [this.selectedPosition.eventIndex];
        }
        return [];
    }

    public hasRange(): boolean {
        return Boolean(this.range);
    }

    public getRange(): TablatureRange | undefined {
        if (!this.range) return undefined;

        const from = { ...this.range.from };
        const to = { ...this.range.to };
        return { from, to };
    }

    public getPosition(): TablaturePosition | undefined {
        return this.selectedPosition ? { ...this.selectedPosition } : undefined;
    }

    public getLastSelectedPosition(): TablaturePosition | undefined {
        return this.lastSelectedPosition ? { ...this.lastSelectedPosition } : undefined;
    }

    /** Sets the editor selection to given position or range */
    public set(selection: TablaturePosition | TablatureRange | undefined): void {
        const editorStore = getEditorStore();
        editorStore.showSuggestions = false;
        if (isTablatureRange(selection)) {
            this.clear();
            this.range = selection;
            this.selectedPosition = selection.from;
        } else {
            this.previousPosition = this.selectedPosition ? { ...this.selectedPosition } : undefined;
            this.clear();
            this.selectedPosition = selection;
        }
    }

    /** Sets the state of the selection and previous selection for undo redo */
    public setState(
        previousPosition: TablaturePosition | undefined,
        position: TablaturePosition | TablatureRange | undefined
    ) {
        this.set(position);
        this.previousPosition = previousPosition;
    }

    /** A selection is considered in progress until the input is finished
     * E.g. when the user is dragging the mouse to select a range or when
     * a mobile user is holding down on a position to select a range.
     *
     * In progress selections are not different from normal selections and
     * merely used to provide a visual feedback to the user.
     */
    public setSelectionInProgress(inProgress: boolean): void {
        this.inProgress = inProgress;
    }

    /** Updates selected range without changing the anchor*/
    public updateRange(range: TablatureRange): void {
        this.range = range;
    }

    public clear(): void {
        this.selectedPosition = undefined;
        this.lastSelectedPosition = undefined;
        this.clearRange();
    }

    public clearRange(): void {
        this.range = undefined;
    }

    private set range(range: TablatureRange | undefined) {
        if (range && (!range.from || !range.to)) {
            throw new Error("Fatal error, trying to set invalid range");
        }
        this._range = range;
    }
}
