<template>
    <page-wrapper>
        <div class="load-failure-container">
            <p class="title">{{ $t("failedToLoadSong") }}</p>
            <p class="subtext">{{ $t("failedToLoadSongReason") }}</p>
            <a href="/">{{ $t("backToSongs") }}</a>
        </div>
    </page-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PageWrapper from "@components/page-wrapper/page-wrapper.vue";
export default defineComponent({
    components: {
        "page-wrapper": PageWrapper,
    },
});
</script>

<style lang="scss" scoped>
.load-failure-container {
    padding-top: 90px;
    padding-bottom: 90px;
    text-align: center;

    .title {
        font-weight: 500;
        padding-bottom: 20px;
    }
}
</style>
