<template>
    <page-wrapper>
        <main-header @searchChange="onSearchChange" :searchPlaceholder="searchPlaceholder"></main-header>
        <songs :searchValue="searchValue" :modes="[pinnedStatus, defaultStatus]" :showCreateSong="true"></songs>
    </page-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SongStatus } from "@common/song-status";
import PageWrapper from "@components/page-wrapper/page-wrapper.vue";
import Songs from "@components/songs/songs.vue";
import MainHeader from "@components/main-header/main-header.vue";
import { getConfigStore } from "../../stores/config.store";
import { RouteLocationNormalizedGeneric } from "vue-router";

function getNotebookFromRouteLocation(routeLocation: RouteLocationNormalizedGeneric): string | undefined {
    return routeLocation.params.notebook ? routeLocation.params.notebook.toString() : undefined;
}

export default defineComponent({
    beforeRouteEnter(to, from, next) {
        // Cannot use this.* here as it is beforeEnter
        if (typeof to.query.appVersion === "string") {
            const configStore = getConfigStore();
            configStore.setAppVersion(to.query.appVersion);
        }

        const configStore = getConfigStore();
        configStore.selectedNotebook = getNotebookFromRouteLocation(to);

        next();
    },
    beforeRouteUpdate(to, from, next) {
        this.$store.config.selectedNotebook = getNotebookFromRouteLocation(to);
        next();
    },
    components: {
        "page-wrapper": PageWrapper,
        "main-header": MainHeader,
        songs: Songs,
    },
    data() {
        return {
            pinnedStatus: SongStatus.Pinned,
            defaultStatus: SongStatus.Default,
            searchValue: "",
        };
    },
    computed: {
        searchPlaceholder(): string {
            const notebook = this.$store.config.selectedNotebook;
            return notebook ? this.$t("searchSongsAtNotebook", { notebook }) : this.$t("searchSongs");
        },
    },
    methods: {
        onSearchChange(value: string): void {
            this.searchValue = value;
        },
    },
});
</script>
