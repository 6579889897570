<template>
    <div class="song-actions-container d-flex flex-row flex-grow-1 justify-content-end" ref="songActionsContainer">
        <ul v-if="listItemsCount > 0" class="song-actions d-flex flex-row">
            <song-actions-list
                :song="song"
                :songActionsOptions="songActionsOptions"
                :includedActions="listActionNames"
                :hideText="true"></song-actions-list>
        </ul>

        <div v-if="dropdownItemsCount > 0" class="song-actions-dropdown">
            <div
                class="btn btn-grey-light dropdown"
                data-bs-toggle="dropdown"
                @keydown.enter="toggleDropdown"
                tabindex="0">
                <i class="bi bi-three-dots-vertical"></i>
            </div>

            <ul class="dropdown-menu toolbar-dropdown">
                <song-actions-list
                    :song="song"
                    :songActionsOptions="songActionsOptions"
                    :includedActions="dropdownActionNames">
                </song-actions-list>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import SongActionsList from "./song-actions-list.vue";
import { defineComponent, PropType } from "vue";
import { Song } from "../../models/song/song";
import { shouldHideAction } from "../../utils/song-actions-utils";
import { SongActionOptions, SongActionsType } from "../../interfaces/song-actions";

export default defineComponent({
    name: "song-actions",
    props: {
        // Some actions need the song information.
        song: {
            type: Object as PropType<Song>,
            required: true,
        },

        // Override global options for specific actions
        songActionsOptions: {
            type: Object as PropType<Record<SongActionsType, SongActionOptions>>,
            default: () => ({}),
        },

        displayMode: {
            type: String as PropType<"auto" | "dropdown">,
            default: "auto",
        },
    },
    data: () => ({
        resizeBind: undefined as any, // Intermediary bind used to properly remove event.
        resizeObserver: undefined as ResizeObserver | undefined,
        containerWidth: 0,

        // The order of the actions in the allActionTypes array must match the rendering
        // order in the SongActionsList component to maintain consistency.
        allActionTypes: [
            "edit",
            "share",
            "pin",
            "moveToNotebook",
            "archive",
            "copy",
            "trash",
            "delete",
        ] as SongActionsType[],

        // The following value needs to be updated if .btn styles change.
        actionItemWidth: 40, // 16px icon + 2 * 12px padding = 40px
    }),
    components: {
        "song-actions-list": SongActionsList,
    },
    mounted(): void {
        this.resizeBind = this.onResize;
        this.resizeObserver = new ResizeObserver(this.resizeBind);
        this.resizeObserver.observe(this.songActionsContainer);
    },
    unmounted(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
    computed: {
        songActionsContainer(): HTMLElement {
            return this.$refs.songActionsContainer as HTMLElement;
        },
        includedActions(): string[] {
            return this.allActionTypes.filter((actionType) => {
                const specificOptions = this.songActionsOptions[actionType] ?? {};
                return !shouldHideAction(actionType, this.song, specificOptions);
            });
        },
        includedActionsCount(): number {
            return this.includedActions.length;
        },
        maxItemsInContainer(): number {
            return Math.floor(this.containerWidth / this.actionItemWidth);
        },
        shouldDisplayDropdown(): boolean {
            if (this.displayMode === "dropdown") {
                return true;
            }

            if (this.includedActionsCount === 1) {
                return false;
            }

            const canFitAllItems = this.includedActionsCount <= this.maxItemsInContainer;
            return !canFitAllItems;
        },
        listItemsCount(): number {
            if (this.displayMode === "dropdown") {
                return 0;
            }

            return this.shouldDisplayDropdown ? this.maxItemsInContainer - 1 : this.includedActionsCount;
        },
        listActionNames(): string[] {
            return this.includedActions.slice(0, this.listItemsCount);
        },
        dropdownItemsCount(): number {
            return this.includedActionsCount - this.listItemsCount;
        },
        dropdownActionNames(): string[] {
            return this.includedActions.slice(this.listItemsCount);
        },
    },
    methods: {
        onResize(): void {
            const safetySpacing = 10;
            this.containerWidth = this.songActionsContainer.clientWidth - safetySpacing;
        },
        toggleDropdown(event: KeyboardEvent): void {
            event.preventDefault();
            event.stopPropagation();

            const button = event.target as HTMLButtonElement;
            button.click();
        },
    },
});
</script>

<style lang="scss" scoped>
.song-actions {
    list-style: none;
    margin: 0;
    padding: 0;
}
</style>
