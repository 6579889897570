<template>
    <div class="settings-page">
        <settings-header></settings-header>

        <main class="settings">
            <div class="container">
                <settings-account></settings-account>
            </div>
            <div class="container">
                <settings-user-preferences></settings-user-preferences>
            </div>
            <div class="container">
                <settings-app></settings-app>
            </div>
        </main>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SettingsHeader from "./settings-header/settings-header.vue";
import SettingsApp from "./settings-app/settings-app.vue";
import SettingsUserPreferences from "./settings-user-preferences/settings-user-preferences.vue";
import SettingsAccount from "./settings-account/settings-account.vue";

export default defineComponent({
    name: "Settings",
    components: {
        "settings-header": SettingsHeader,
        "settings-app": SettingsApp,
        "settings-user-preferences": SettingsUserPreferences,
        "settings-account": SettingsAccount,
    },
});
</script>

<style lang="scss" scoped>
.settings-page {
    background-color: var(--background-color-primary);
    height: calc(100% - 62px);
    margin-top: 62px;
    overflow: auto;
    padding: 0 30px;

    .settings {
        flex-direction: column;
        display: flex;
        gap: 30px;
        padding-bottom: 60px;
        padding-top: 30px;

        .container {
            align-items: stretch;
            background-color: var(--background-color-secondary);
            border-radius: 15px;
            flex-direction: column;
            padding: 30px;
            padding-bottom: 46px; // Extra 16ox to compensate for 'legend' margin-top

            form {
                margin: 0 auto;

                &:not(:last-child) {
                    margin-bottom: 100px;
                }
            }
        }
    }
}
</style>
