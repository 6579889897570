<template>
    <form class="row g-3" @click.prevent>
        <legend>{{ $t("settingsUserPreferences") }}</legend>
        <div class="form-group col-12">
            <label for="languageSelector" class="form-label">Select language:</label>
            <select
                class="form-select form-select-lg"
                id="languageSelector"
                v-model="selectedLanguage"
                @change="changeLanguage">
                <option value="en">English</option>
                <option value="es">Español</option>
            </select>
        </div>
    </form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { api } from "../../../apis/api";

export default defineComponent({
    data() {
        return {
            selectedLanguage: this.$i18n.locale,
        };
    },
    methods: {
        changeLanguage() {
            this.$i18n.locale = this.selectedLanguage;
            api.localStorage.set("userLocale", this.selectedLanguage);
        },
    },
});
</script>
