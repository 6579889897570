<template>
    <div>
        <a
            href="#"
            v-if="!hide"
            class="btn"
            :class="{ disabled, flat }"
            :title="tooltip"
            tabindex="0"
            data-bs-toggle="dropdown"
            :aria-label="text">
            <i class="bi" :class="{ 'bi-journal-bookmark-fill': hasLabel, 'bi-journal-bookmark': !hasLabel }"></i>
            <span v-if="!hideText">{{ text }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-end" data-bs-popper="static">
            <input
                type="text"
                class="form-control search-notebook"
                :maxlength="maxLabelLength"
                v-model="searchValue"
                :placeholder="$t('searchNotebookPlaceholder')"
                @keydown.enter="onInput" />

            <li
                v-for="notebook in notebooks"
                class="btn"
                @click="moveTo(notebook)"
                @keydown.enter="moveTo(notebook)"
                tabindex="0">
                {{ notebook }}
            </li>

            <hr v-if="canMoveToSongs || canCreateNotebook" class="dropdown-divider" />
            <li
                v-if="canMoveToSongs"
                class="btn"
                @click="moveTo(undefined)"
                @keydown.enter="moveTo(undefined)"
                tabindex="0">
                {{ $t("actionMoveToSongs") }}
            </li>
            <li
                v-if="canCreateNotebook"
                class="btn"
                @click="moveToNewNotebook(searchValue)"
                @keydown.enter="moveToNewNotebook(searchValue)"
                tabindex="0">
                {{ $t("actionMoveToNewNotebook", { notebook: searchValue }) }}
            </li>
        </ul>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Song } from "../../../models/song/song";
import { api } from "../../../apis/api";
import { SongActionOptions } from "../../../interfaces/song-actions";
import { isWhitespaceString } from "../../../utils/utils";
import { shouldHideAction } from "../../../utils/song-actions-utils";
import { MAX_LABEL_LENGTH, MAX_NOTEBOOKS } from "../../../../common/constants";

export default defineComponent({
    name: "song-action-archive",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        songActionOptions: {
            type: Object as PropType<SongActionOptions>,
            default: () => ({}),
        },
        flat: {
            type: Boolean,
            default: false,
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            searchValue: "",
            actionInProgress: false,
        };
    },
    computed: {
        hide(): boolean {
            return shouldHideAction("moveToNotebook", this.song, this.songActionOptions);
        },
        disabled(): boolean {
            return this.actionInProgress || this.songActionOptions.disabled === true;
        },
        text(): string {
            return this.$t("actionMoveToNotebook");
        },
        tooltip(): string {
            return this.text;
        },
        songName(): string {
            return isWhitespaceString(this.song.title) ? this.$t("song") : this.song.title;
        },
        notebooks(): string[] {
            return this.$store.config.userNotebooks.filter((notebook) => {
                if (notebook === this.$store.config.selectedNotebook) {
                    return false;
                }
                if (this.searchValue !== "") {
                    return notebook.toLowerCase().includes(this.searchValue.toLowerCase());
                }

                return true;
            });
        },
        maxLabelLength(): number {
            return MAX_LABEL_LENGTH;
        },
        hasLabel(): boolean {
            return (this.song.metadata.labels ?? []).length > 0;
        },
        canMoveToSongs(): boolean {
            return this.hasLabel;
        },
        notebookCount(): number {
            return this.$store.config.userNotebooks.length;
        },
        canCreateNotebook(): boolean {
            if (this.searchValue.trim().length === 0) {
                return false;
            }

            return !this.$store.config.userNotebooks.some((notebook) => {
                return notebook.toLowerCase() === this.searchValue.toLowerCase();
            });
        },
    },
    methods: {
        onInput(): void {
            if (this.searchValue.trim().length === 0) {
                return;
            }

            const foundNotebook = this.$store.config.userNotebooks.find((notebook) => {
                return notebook.toLowerCase() === this.searchValue.toLowerCase();
            });

            if (foundNotebook) {
                this.moveTo(foundNotebook);
            } else {
                this.moveToNewNotebook(this.searchValue);
            }
        },
        moveToNewNotebook(notebook: string): void {
            if (this.notebookCount >= MAX_NOTEBOOKS) {
                this.$bus.emit("error", this.$t("notebookLimitError"));
            } else {
                this.moveTo(notebook);
            }
        },
        async moveTo(notebook: string | undefined): Promise<void> {
            const updatedSong = this.song.copy();
            let messageKey: string;
            if (notebook) {
                api.log.track("moveToNotebook");
                updatedSong.metadata.labels = [notebook];
                messageKey = "actionCompleteMoveToNotebook";
            } else {
                updatedSong.metadata.labels = [];
                messageKey = "actionCompleteMoveToSongs";
            }
            this.actionInProgress = true;
            await this.$store.song.update(updatedSong);
            this.actionInProgress = false;

            this.$bus.emit("info", this.$t(messageKey, { songName: this.songName, notebook: notebook }));
        },
    },
});
</script>

<style lang="scss" scoped>
.search-notebook {
    margin: 10px;
    width: calc(100% - 20px);
}
</style>
