import type { TuningOption } from "./types";

export const TABLATURE_MAX_EVENTS = 5000;
export const MAX_STRINGS = 20;
export const MAX_STRING_LENGTH = 100;
export const MAX_NOTEBOOKS = 10;
export const MAX_LABEL_LENGTH = 30;
export const TABLATURE_PREVIEW_SIZE = 20;
export const EXTRA_ROWS_IN_TABLATURE = 1;
export const EDITOR_TABLATURE_EXTRA_HEIGHT = 256;
export const MAX_SONGS = 300;

export enum LoadingStatus {
    UNINITIALIZED,
    LOADING,
    RELOADING,
    READY,
    ERROR,
}

export enum SyncStatus {
    LOGGED_OUT,
    OFFLINE,
    IN_PROGRESS,
    DONE,
    ERROR,
    NOT_SYNCED,
}

export const DEFAULT_TUNING: TuningOption[] = ["E", "B", "G", "D", "A", "E"];

export const TUNING_OPTIONS: TuningOption[] = ["E", "F", "F#", "G", "G#", "A", "A#", "B", "C", "C#", "D", "D#"];
